<template>
    <div>
        <card-header title="Customer Registrations" icon="fa-suitcase"/>
        <card-body>
            <card-list>
                <subheader-list-item title="Registrations" icon="fas fa-suitcase"/>

                <customer-reg-list-item
                        :card="card"
                        :registration="registration"
                        v-for="registration in registrations"
                        :active="child && child.definition.component === 'registration' && child.props.registrationId === registration.id.toString()"
                        @click="$openCard('registration', {registrationId: registration.id}, card)"
                />
            </card-list>
        </card-body>

        <card-footer v-if="props.tripId">
            <strong>Total Registrations: </strong>{{registrations.length}}
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import RegistrationListItem from "../../components/RegistrationListItem";
    import CardFooter from "../../TIER/components/CardFooter";
    import CustomerRegListItem from "@/components/CustomerRegListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {CustomerRegListItem, CardFooter, RegistrationListItem, CardBody, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                registrations: []
            }
        },
        methods: {
            loadRegistrations: function(force) {
                this.$emit('loading', true);

                http.get('/api/users/' + this.props.userId + '/registrations', {ttl: 30, force}).then(response => {
                    this.registrations = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function () {
                this.loadRegistrations(true);
            },
        },
        watch: {
            'props.tripId': function() {
                this.loadRegistrations();
                this.loadTrip();
            }
        },
        mounted() {
            this.loadRegistrations();
        }
    };
</script>
